<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!--<div><el-button icon="el-icon-search" @click="exports()">导出表格</el-button></div>-->
                    <div><el-input v-model="where.F_VR_DINGDH" placeholder="请输入订单号"></el-input></div>
                    <div><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                    <div style="width:160px;">
                        <el-select v-model="where.F_TI_DINGDZT" placeholder="选择类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="待付款" value="1"></el-option>
                            <el-option label="待评价" value="2"></el-option>
                            <el-option label="已完成" value="3"></el-option>
                        </el-select>
                    </div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="70px"></el-table-column>
                    <el-table-column prop="F_VR_DINGDH" label="订单号"></el-table-column>

                    <el-table-column prop="F_VR_ZONGJ" label="总计"  width="50px"></el-table-column>

                    <!--<el-table-column prop="F_VR_MENDDZ" label="门店地址" width="170px"></el-table-column>-->
                    <el-table-column prop="adv_start" label="创建时间"  width="100px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="订单状态" width="75px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.F_TI_DINGDZT==1">待付款</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_DINGDZT==2">待评价</el-tag>
                            <el-tag type="success" v-else-if="scope.row.F_TI_DINGDZT==3">已完成</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column prop="F_VR_XINGM" label="姓名"  width="50px"></el-table-column>
                    <el-table-column prop="F_VR_SHOUJ" label="手机号"  width=""></el-table-column>
                    <el-table-column prop="F_VR_QUY" label="区域"  width=""></el-table-column>
                    <el-table-column prop="F_VR_XIANGXDZ" label="详细地址"  width=""></el-table-column>


                    <el-table-column prop="F_VR_KUAIDF" label="快递方式"></el-table-column>
                    <el-table-column prop="F_VR_KUAIDDH" label="快递单号"></el-table-column>
                   <!-- <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'adv_form',params:{id:scope.row.id}})">编辑</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->

                    <el-table-column label="操作" fixed="right" width="240px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-bank-card" @click="change_user_money(scope.row.F_IN_ID)">快递单号</el-button>
                            <el-button icon="el-icon-edit" @click="$router.push('/Admin/xiangcscdd/info/'+scope.row.F_IN_ID)">查看详情</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                    <div slot="append">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit center' >总金额：{{zongje}}</div>
                            <div class='sum_footer_unit center' >订单数：{{dingds}}</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                        </div>
                    </div>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>




        <!-- 调整资金弹出框 -->
        <el-dialog title="快递单号" :visible.sync="changeShow" width="30%">
            <el-form ref="form"  label-width="90px">
                <el-form-item label="快递方：">
                    <el-input v-model="change_info.kuaidf"></el-input>
                </el-form-item>
                <el-form-item label="快递单号：">
                    <el-input v-model="change_info.danh"></el-input>
                </el-form-item>
            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="changeShow = false">取 消</el-button>
                <el-button type="primary" @click="change_start()">确 定</el-button>
            </span>
        </el-dialog>





    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
              F_VR_DINGDH:'',
              F_TI_DINGDZT:'',
              times:[],
          },
          zongje:0,
          dingds:0,
          changeShow:false, // 显示调整输入框
          change_info:{
              kuaidf:'',
              danh:'',
          },
          dingd_id:0


      };
    },
    watch: {},
    computed: {},
    methods: {
        change_user_money:function(id){
            this.dingd_id = id;
            this.changeShow = true;
        },
        change_start:function(){
            this.change_info.dingd_id = this.dingd_id;
            this.$post(this.$api.adminChangeDingdh,this.change_info).then(res=>{
                if(res.code == 200){
                    this.changeShow = false;
                    this.$message.success('修改成功');
                    this.get_adv_list();
                }else{
                    this.$message.error(res.msg);
                }
            });
        },
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            let where = {};
            where.page = this.current_page;
            where.params = this.where;
            this.$get(this.$api.getXiangcscddList,where).then(res=>{
                this.page_size = res.data.data.per_page;
                this.total_data = res.data.data.total;
                this.current_page = res.data.data.current_page;
                this.list = res.data.data.data;
                this.zongje = res.data.zongje;
                this.dingds = res.data.dingds;
            })

        },
        exports:function(){
            if(this.where.times[0]){
                this.where.times[0] = this.where.times[0].toGMTString();
                this.where.times[1] = this.where.times[1].toGMTString();
            }
            window.open(`http://api.ambanyongjiu.com/api/excel/orderdaoc?xiansx=${this.where.F_TI_DINGDZT}&timeone=${this.where.times[0]}&timetwo=${this.where.times[1]}`, '_self');
        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delXiangcscdd,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
    .table_dl_dd_all{
        height: 50px;
        overflow: hidden;
    }
    .sum_footer{
        display:flex;
        display:-webkit-flex;
        line-height:50px;
        color:#606266;
    }
    .sum_footer_unit{
        flex-grow:1;
        -webkit-flex-grow:1;
        text-indent:10px;
        font-size:14px;
        font-weight:bold;
    }
    .sum_footer_unit.center{
        text-align:center;
    }
    .sum_footer.xiaoji{
        border-bottom:1px solid #EBEEF5;
    }

    .admin_main_block_left div {
        float: left;
        margin-right: 2px;
    }

    .el-range-editor--small .el-range-input {
        font-size: 13px;
        width: 120px;
    }
</style>